<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /

            <router-link :to="{name: 'rolelink' }" class="root">
                Librarian
            </router-link>
            /
            <router-link :to="{name: 'reports' }" class="root">
                Reports
            </router-link>
            /
            <span class="child">Over Due Report </span>
        </v-row>
        <br />

        <v-card class="card">
            <v-card-title class="heading justify-center"> Total Fine User Wise</v-card-title>

       

            <div class="">
                <div class="add-section">
                    <div class="d-flex">
                        <v-col>
                            <label class="add-text">Program</label>{{dept}}
                            <v-autocomplete v-model="selected_dept" placeholder="Select Series" :items="dept_list" item-text="name" item-value="id" outlined dense class="text" :rules="[rules.required]" clearable required></v-autocomplete>
                        </v-col>
                        <v-col>
                            <label class="add-text">Year</label>{{dept}}
                            <v-autocomplete v-model="selected_year" placeholder="Select Year" :items="year_list" item-text="name" item-value="id" outlined dense class="text" :rules="[rules.required]" clearable required></v-autocomplete>
                        </v-col>
                        <v-col>
                            <label>Start Date</label>

                            <v-menu v-model="fromDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field class="text" v-model="startDate" placeholder="Select Start Date" append-icon="event" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="startDate" no-title @input="fromDateMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col>
                            <label>End Date</label>

                            <v-menu v-model="fromDateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field class="text" v-model="endDate" placeholder="Select End Date" append-icon="event" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="endDate" no-title @input="fromDateMenu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col>
                            <label class="add-text">User Type</label>
                            <v-autocomplete v-model="usertype" placeholder="Select User Type" :items="usertype_data" item-text="name" item-value="id" outlined class="text" dense :rules="[rules.required]" clearable required></v-autocomplete>
                        </v-col>
                       
                      
                    </div>

                    <div>
                        <center>
                            <v-btn color="success" class="mb-4" @click="overdueData">Fetch Data</v-btn>
                        </center>
                    </div>
                </div>
                 
            </div>
        </v-card>
        <br>
        <div>
                    <v-row justify="end" class="mr-5">
        <v-btn v-if="bl_data.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
          <v-icon dark>mdi-file-excel</v-icon>
        </v-btn>
      </v-row>
         <div v-if="load">
  <v-skeleton-loader
          v-bind="attrs"
          type="three-line,  article"
        ></v-skeleton-loader>

  </div>
  <div v-else>
      <v-simple-table id="exceltable"  class="elevation-1" :search="search" v-if="bl_data.length!=0">
       
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left"> Sr. No. </th>
             
              <th class="text-left"> PRN No./Emp Id</th>
              <th class="text-left"> Name</th>
              <th class="text-left"> Year</th>
              <th class="text-left"> Program</th>
              <th class="text-left"> List Of Fine</th>
         
              <th class="text-left"> Total Fine (Rs.)</th>
            </tr>
          </thead>
          <tbody>
          
            <tr 
              v-for="item in bl_data"
              :key="item.id"
            >
              <td>{{ item.srno }}</td>
          
              <td>{{ item.PRN_EMP}}</td>
              <td>{{ item.name}}</td>
              <td>{{ item.year}}</td>
              <td>{{ item.dept}}</td>
              <td>{{ item.fine_array}}</td>
             
              <td>{{ item.fine}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
  </div>
        </div>

               
     
    </div>
</template>

<script>
    import axios from "axios";
    import moment from "moment";
    import {table2excel} from "../jquery.table2excel";
    import { jsPDF } from "jspdf";
    import "jspdf-autotable";

    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            selected_year:"",
            year_list:[],
            dept_list:[],
                selected_dept:"",
            showreturn:false,
            load:false,
            fromDateMenu1 : false,
            dept: null,
            series:null,
            fromDateMenu1: false,
            usertype: null,
            valid: false,
            overlay: false,
            userid: "",
            bookitemid: "",
            selected:[],
            bl_data:[],
            errorMsg: {
                roleLinkId: false,
            },
            rules: {
                required: (value) => !!value || "Required.",
            },
            snackbar: false,
            snackbar_msg: "",
            org: "",
            dialog: false,
            fromDateMenu: false,
            fromDateVal: [],
            startDate: "",
            endDate: "",
            overDueData: [],
            dept_data: [],
            series_data:[],
            usertype_data: [],
            minDate: "2020-01-05",
            maxDate: new Date(),
            dept_name: "",
            user_type: "",
           
       
            csv_header_data:[],
            table_header_data:[],
            filters: {
                acc_no:[],
                authors:[],
                duedate:[],
                lateDays:[],
                member_name:[],
                publisher:[],
                srno:[] ,
                startdate:[] ,
                title:[],
               
            },
            colData:{}
        }),
        computed: {
            fromDateDisp() {
                return this.fromDateVal;
                // format/do something with date
            },
          
            dateRangeText() {
                return this.dates.join(" ~ ");
            },
        },

        mounted() {
            this.onLoad();
        },
        methods: {
          
            
           

          

            overdueData() {
               // alert(+this.series+" | "+this.usertype+" |"+this.startDate+" | "+this.endDate);
                this.overlay = true;
                let abc = Object.assign({}, this.selected);

                // for(let i = 0;i<this.selected.length;i++){
                //     let key = abc[i]
                //     this.colData[key] = key
                // }

                if (this.startDate == null || this.endDate == null || this.usertype == null ) {
                    this.showSnackbar("#b71c1c", "All fields are required!!");
                } else {
                    var showreturn="false";
                   if(this.showreturn)
                            showreturn="true";
                    const data = {
                        isreturn:showreturn,
                        firstDate: moment(String(this.startDate)).format("DD-MM-YYYY"),
                        lastDate: moment(String(this.endDate)).format("DD-MM-YYYY"),
                        prog:this.selected_dept,
                        year:this.selected_year,
                        user_id: this.usertype,
                        
                        // colData: this.colData
                    };
                    this.load=true;
                    axios
                        .post("/Librarian/totalfineuserwise", data)
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.overlay = false;
                             
                                this.bl_data = res.data.bl_data;
                                                        this.load=false;
                           
                                if (this.bl_data.length == 0) {
                                    this.showSnackbar("#b71c1c", "No data available ");
                                }
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong.."); // show snackbar
                            window.console.log(error);
                            this.load=false;
                        })
                        .finally(() => {
                            this.load=false;
                        });
                }
            },
             exportexcel() {    
        $("#exceltable").table2excel({  
          name: "Worksheet Name",
          filename: "Over_Due_Report", //do not include extension
          fileext: ".xls" // file extension
        });        
      },
            onLoad() {
                this.overlay = true;
                axios
                    .post("/Librarian/getdeptData")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.endDate = new Date().toJSON().slice(0,10);
                     //this.selectedfromdate = new Date().setMonth(this.selectedtodate.getMonth() - 1);
                     var d = new Date();
                      d.setMonth(d.getMonth() - 1);
                      this.startDate = d.toJSON().slice(0,10);
                            this.dept_list = res.data.program_list;
                           
                            this.usertype_data = res.data.usertype_data;
                            this.year_list = res.data.year_list;
                            this.dept_list.push({
                            name: 'ALL',
                            id: 'ALL'
                            });
                            this.selected_dept="ALL"
                            this.year_list.push({
                            name: 'ALL',
                            id: 'ALL'
                            });
                            this.selected_year='ALL'

                            
                            }
                           
                            
                    })
                     
 
    
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
          
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .cus-card {
        background: #d3d3d33d;
        margin: 1rem;
        padding: 1rem;
        border: 2px solid gray;
    }
    .c-label {
        font-weight: bold;
    }
    .add-section {
        display: block;
        margin: 1rem;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
    .elevation-1 /deep/ tr {
        white-space: nowrap !important;
    }
    .d-btn {
        display: flex;
        justify-content: flex-end;
        margin: 0.5rem;
    }
        .checkbox{
        margin: 1rem;
    border: 1px solid;
    background: #d3d3d380;
    }
    .checkbox .box{
        margin-top: 5px;
    }
        .checkbox /deep/ .v-input--selection-controls{
        margin-top: 0px  !important;
    }
</style>
